<!-- 模块说明:代理管理 -->
<template>
  <div class="container">
    <div class="view">
      <div class="flex">
        <div class="title">客户详情</div>
        <div class="flex">
          <el-date-picker
            class="waicengPhone"
            v-model="searchEle.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          >
          </el-date-picker>
          <el-input
            class="waicengPhone"
            v-model="searchEle.terName"
            placeholder="请输入客户名称"
            clearable
            maxlength="11"
          ></el-input>
          <el-input
            class="waicengPhone"
            v-model="searchEle.terPhone"
            placeholder="请输入手机号"
            clearable
            maxlength="11"
          ></el-input>
          <el-button
            @click="(currentPage = 1), getfactorysList()"
            type="primary"
          >
            查询
          </el-button>
          <el-button @click="goBack" type="primary" plain> 返回 </el-button>
        </div>
      </div>
      <el-table :data="tableData" stripe class="HNMR">
        <el-table-column prop="terName" label="客户名称"> </el-table-column>
        <el-table-column prop="terPhone" label="手机号"> </el-table-column>
        <el-table-column prop="liveRPTime" label="创建时间">
          <template slot-scope="scope">
            {{ Dateformat(scope.row.gmtCreate, "YYYY-MM-DD HH:mm") || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="创建人"></el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button @click="openRecord(scope.row)" type="text"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-pagination
          :current-page.sync="currentPage"
          :page-size="pageSize"
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="totalNum"
          @current-change="handelCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-row>
    </div>
    <el-dialog
      class="commonDialog"
      title="查看详情"
      :visible.sync="recordPopup.show"
      width="900px"
    >
      <recordPopup
        ref="record"
        :type="1"
        :detailData="recordPopup.res"
      ></recordPopup>
    </el-dialog>
  </div>
</template>

<script>
import recordPopup from "../../components/recordPopup.vue";
export default {
  components: { recordPopup },
  props: [],
  data() {
    return {
      searchEle: {},
      tableData: [],
      totalNum: 0,
      currentPage: 1,
      pageSize: 10,
      recordPopup: {
        show: false,
        res: {},
      },
    };
  },
  watch: {},
  created() {
    this.getfactorysList();
  },
  methods: {
    //打开充值记录弹窗并赋值
    openRecord(row) {
      this.recordPopup.res = row;
      this.recordPopup.show = true;
      setTimeout(() => {
        this.$refs.record.getAllremain();
        this.$refs.record.getRecordList();
      }, 200);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getfactorysList();
    },
    //翻页
    handelCurrentChange(page) {
      this.currentPage = page;
      this.getfactorysList();
    },
    //获取列表数据
    getfactorysList() {
      this.http
        .get("/terminal/customer/getTerminalCustomerList", this.returnParams())
        .then((res) => {
          this.tableData = res.data.data;
          this.totalNum = res.data.totalRecords;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //筛选项
    returnParams() {
      let params = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        amId: this.$route.query.amId,
      };
      params = Object.assign({}, this.searchEle, params);
      if (params.time) {
        params.gmtCreateStartDate = params.time[0];
        params.gmtCreateEndDate = params.time[1];
        delete params.time;
      }
      return params;
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.container {
  .view {
    .title {
      color: white;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    margin-top: 20px;
  }
  margin: auto;
}
</style>
